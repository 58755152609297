import user from './user';
import client from './client';
import project from './project';

const task = {
	mixins: [user, client, project],
	data() {
		return {
			task: {
				ID: null,
				Project: null,
				Client: null,
				Priority: null,
				TaskName: null,
				Description: null,
				CreatedBy: null,
				CreatedDate: null,
				AssignedTo: null,
				Estimated_Points: null,
				DueDate: null,
				Completed: null,
				Status: null,
				status_text: null,
				date: null,
				assignee: {},
				user: {},
				client: {},
				project: {}
			},
		}
	},
	methods: {
		fetchTask(id) {
			this.$store.dispatch('task/fetchTask', {id: id}).then(data => {
				this.setTask(data.data);
				this.setUserModel(this.assignee, this.task.assignee);
				this.setUserModel(this.user, this.task.user);
				this.setClient(this.task.client);
				this.setProject(this.task.project);
			});
		},
		setTask(data) {
			Object.assign(this.task, data);
		},
		taskPMLink(task) {
			return 'https://www.secured-site7.com/one/index.html?mdl=tasks/task_form.mdl&TaskID=' + task.ID + '&Company=' + task.Company;
		}
	},
};

export default task;