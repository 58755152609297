import script from "./ModalComponent.vue?vue&type=script&lang=js"
export * from "./ModalComponent.vue?vue&type=script&lang=js"
/* hot reload */
if (module.hot) {
  script.__hmrId = "054686c8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('054686c8', script)) {
    api.reload('054686c8', script)
  }
  
}

script.__file = "src/js/components/ModalComponent.vue"

export default script