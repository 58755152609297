<template>
	<div class="container-fluid main-container">
		<modal-component/>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'AppComponent'
	}
</script>

<style scoped>

</style>