import _ from 'lodash';

import SchedulerComponent from '../components/SchedulerComponent';

import taskRoutes from './routes/tasks';

const home = {
	path: '/',
	name: 'home',
	component: SchedulerComponent
};

const routes = _.concat([
		home,
	],
	...taskRoutes
);

export default routes;