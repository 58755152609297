<template>
	<div class="scheduler-actions row my-3">
		<div class="col-2">
			<label class="form-label">View Type</label>
			<select
				class="form-select"
				v-model="zoom"
				@change.prevent="changeZoomLevel"
			>
				<option value="0">Month</option>
				<option value="1">Week</option>
				<option value="2">Day</option>
			</select>
		</div>
		<div class="col-2">
			<input
				type="checkbox"
				class="btn-check"
				id="btn-check"
				autocomplete="off"
				v-model="onHold"
				@change.prevent="changeOnHold"
			>
			<label
				class="btn btn-primary align-middle"
				for="btn-check"
			>
				{{ onHold ? 'Hide' : 'Show' }} On Hold
			</label>
		</div>
	</div>
	<div class="my-3">
		<span class="badge rounded-pill completed-badge me-1">Completed</span>
		<span class="badge rounded-pill in-progress-badge text-dark me-1">In Progress</span>
		<span class="badge rounded-pill waiting-badge me-1">Waiting On Customer</span>
		<span class="badge rounded-pill on-hold-badge me-1">On Hold</span>
		<span class="badge rounded-pill bg-secondary me-1">Unclaimed</span>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'SchedulerActions',
		computed: {
			...mapState({
				zoomLevel: state => state.scheduler.zoomLevel,
				showOnHold: state => state.scheduler.showOnHold,
			}),
		},
		data() {
			return {
				zoom: 0,
				onHold: true
			}
		},
		methods: {
			changeZoomLevel() {
				this.$store.dispatch('scheduler/setZoomLevel', {level: parseInt(this.zoom)});
			},
			changeOnHold() {
				this.$store.dispatch('scheduler/setOnHoldVisibility', {visible: this.onHold});
			}
		},
		watch: {
			zoomLevel(val) {
				this.zoom = val;
			},
			showOnHold(val) {
				this.onHold = val;
			},
		}
	}
</script>

<style scoped>

</style>