import { createApp } from 'vue';

import SchedulerComponent from './components/SchedulerComponent';
import AppComponent from './components/AppComponent';
import TaskModal from './components/tasks/TaskModal';

import store from './store/store';
import router from './router/router';

import modalPlugin from './plugins/modal';

require('./axios_interceptors');

const app = createApp(AppComponent);

app.component('AppComponent', AppComponent);
app.component('SchedulerComponent', SchedulerComponent);
app.component('TaskModal', TaskModal);

app.use(store);

app.use(router);

app.use(modalPlugin);

app.mount('#app');