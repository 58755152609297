import axios from 'axios';

const scheduler = {
	namespaced: true,
	state: {
		zoomLevel: 0,
		showOnHold: true
	},
	mutations: {
		setZoomLevel(state, level) {
			state.zoomLevel = level;
		},
		setOnHoldVisibility(state, visible) {
			state.showOnHold = visible;
		}
	},
	actions: {
		fetchResources() {
			return axios.get('tasks/resources');
		},
		setZoomLevel({commit}, {level}) {
			commit('setZoomLevel', level);
		},
		setOnHoldVisibility({commit}, {visible}) {
			commit('setOnHoldVisibility', visible);
		}
	}
};

export default scheduler;