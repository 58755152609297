import TaskDetail from '../../components/tasks/TaskDetail';

let taskDetail = {
	path: '/task/:id',
	name: 'task.detail',
	component: TaskDetail,
	props: true
};

const taskRoutes = [
	taskDetail
];

export default taskRoutes;