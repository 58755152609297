import {emitter} from '../utils/emitter';
import ModalComponent from '../components/ModalComponent';

const show = (component, props = {}, title = '') => {
	let e = {
		component,
		props,
		title
	};
	emitter.emit('showModal', e);
}

const hide = () => {
	emitter.emit('hideModal');
}

const title = (title = '') => {
	emitter.emit('modalTitle', {title});
}

const modalPlugin = {
	install: (app) => {
		app.component('ModalComponent', ModalComponent);

		app.config.globalProperties.$modal = {
			show,
			hide,
			title
		};
	},
	show,
	hide,
	title
};

export default modalPlugin;