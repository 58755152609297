<template>
	<div class="py-3 mx-auto col-8">
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Name
				</label>
			</div>
			<div class="col-8">
				<p>{{ task.TaskName }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Assigned To
				</label>
			</div>
			<div class="col-8">
				<p>{{ assignee.First_Name }} {{ assignee.Last_Name }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Assigned By
				</label>
			</div>
			<div class="col-8">
				<p>{{ user.First_Name }} {{ user.Last_Name }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Client
				</label>
			</div>
			<div class="col-8">
				<p>{{ client.Company }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Project
				</label>
			</div>
			<div class="col-8">
				<p>{{ project.ProjectName }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Due Date
				</label>
			</div>
			<div class="col-8">
				<p>{{ task.date }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Estimated Points
				</label>
			</div>
			<div class="col-8">
				<p>{{ task.Estimated_Points }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label class="form-label">
					Status
				</label>
			</div>
			<div class="col-8">
				<p>{{ task.status_text }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import task from '../../mixins/task';

	export default {
		name: 'TaskDetail',
		mixins: [task],
		props: {
			id: {
				required: true
			}
		},
		mounted() {
			this.fetchTask(this.id);
		}
	}
</script>

<style scoped>

</style>