import { render } from "./AppComponent.vue?vue&type=template&id=27fab6a8&scoped=true"
import script from "./AppComponent.vue?vue&type=script&lang=js"
export * from "./AppComponent.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-27fab6a8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "27fab6a8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('27fab6a8', script)) {
    api.reload('27fab6a8', script)
  }
  
  module.hot.accept("./AppComponent.vue?vue&type=template&id=27fab6a8&scoped=true", () => {
    api.rerender('27fab6a8', render)
  })

}

script.__file = "src/js/components/AppComponent.vue"

export default script